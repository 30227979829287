"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  intro: '这是 {{ framework }} UI 可视化编程辅助工具，',
  helpDoc: '详见文档',
  tooltip: '。（此气泡只在 dev 模式下出现）',
  offline: 'UI 连接失败',
  restart: '请尝试重启 dev 服务',
  loading: '加载中',
  code_1002: 'Umi UI 出于项目安全考虑，不允许非本地 IP 访问，如果希望非本地 IP 访问，启动时加上 HOST=0.0.0.0 环境变量'
};
exports.default = _default;