"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _zhCN = _interopRequireDefault(require("./zh-CN"));

var _enUS = _interopRequireDefault(require("./en-US"));

var _zhTW = _interopRequireDefault(require("./zh-TW"));

var _ptBR = _interopRequireDefault(require("./pt-BR"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _default = {
  'zh-CN': _zhCN.default,
  'en-US': _enUS.default,
  'zh-TW': _zhTW.default,
  'pt-BR': _ptBR.default
};
exports.default = _default;