"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isRelativeToViewport = isRelativeToViewport;
exports.getScrollBarSize = exports.getClientWidth = exports.getClientHeight = exports.getScrollOffsets = exports.getLocale = exports.render = void 0;

// render locale format
var render = function render(template, model) {
  return template.replace(/{{ (\w+) }}/g, function (str, key) {
    return model[key];
  });
};

exports.render = render;

var getLocale = function getLocale() {
  // support SSR
  var _window = window,
      _window$g_langSeparat = _window.g_langSeparator,
      g_langSeparator = _window$g_langSeparat === void 0 ? '-' : _window$g_langSeparat,
      g_lang = _window.g_lang;
  var lang = typeof localStorage !== 'undefined' ? window.localStorage.getItem('umi_locale') : '';
  var isNavigatorLanguageValid = typeof navigator !== 'undefined' && typeof navigator.language === 'string';
  var browserLang = isNavigatorLanguageValid ? navigator.language.split('-').join(g_langSeparator) : '';
  return lang || g_lang || browserLang || 'zh-CN';
};

exports.getLocale = getLocale;

var getScrollOffsets = function getScrollOffsets() {
  if (window.pageXOffset != null) {
    return {
      x: window.pageXOffset,
      y: window.pageYOffset
    };
  } // For IE (or any browser) in Standards mode


  var _window2 = window,
      document = _window2.document;

  if (document.compatMode === 'CSS1Compat') {
    return {
      x: document.documentElement.scrollLeft,
      y: document.documentElement.scrollTop
    };
  }

  return {
    x: document.body.scrollLeft,
    y: document.body.scrollTop
  };
};

exports.getScrollOffsets = getScrollOffsets;

var getClientHeight = function getClientHeight() {
  return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
};

exports.getClientHeight = getClientHeight;

var getClientWidth = function getClientWidth() {
  if (window.innerWidth) {
    return window.innerWidth;
  }

  return document.body.clientWidth;
};

exports.getClientWidth = getClientWidth;
var cached;

var getScrollBarSize = function getScrollBarSize(fresh) {
  if (window.innerWidth > document.body.offsetWidth) {
    if (fresh || cached === undefined) {
      var inner = document.createElement('div');
      inner.style.width = '100%';
      inner.style.height = '200px';
      var outer = document.createElement('div');
      var outerStyle = outer.style;
      outerStyle.position = 'absolute';
      outerStyle.top = 0;
      outerStyle.left = 0;
      outerStyle.pointerEvents = 'none';
      outerStyle.visibility = 'hidden';
      outerStyle.width = '200px';
      outerStyle.height = '150px';
      outerStyle.overflow = 'hidden';
      outer.appendChild(inner);
      document.body.appendChild(outer);
      var widthContained = inner.offsetWidth;
      outer.style.overflow = 'scroll';
      var widthScroll = inner.offsetWidth;

      if (widthContained === widthScroll) {
        widthScroll = outer.clientWidth;
      }

      document.body.removeChild(outer);
      cached = widthContained - widthScroll;
    }

    return cached;
  }

  return 0;
};

exports.getScrollBarSize = getScrollBarSize;
var relativeToViewport;

function isRelativeToViewport() {
  if (relativeToViewport != null) return relativeToViewport;
  var x = window.pageXOffset ? window.pageXOffset + window.innerWidth - 1 : 0;
  var y = window.pageYOffset ? window.pageYOffset + window.innerHeight - 1 : 0;
  if (x === 0 && y === 0) return true; // Test with a point larger than the viewport. If it returns an element,
  // then that means elementFromPoint takes page coordinates.

  return relativeToViewport === !document.elementFromPoint(x, y);
}