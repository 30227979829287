"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  intro: '這是 {{ framework }} UI 可視化編程輔助工具，',
  tooltip: '。（此氣泡只在 dev 模式下出現）',
  helpDoc: '詳見文檔',
  offline: 'UI 連接失敗',
  restart: '請嘗試重啟 dev 服務',
  loading: '加載中',
  code_1002: 'Umi UI 出於項目安全考慮，不允許非本地 IP 訪問，如果希望非本地 IP 訪問，啟動時加上 HOST=0.0.0.0 環境變量'
};
exports.default = _default;