"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  intro: 'Este é o auxílio à programação visual do  {{ framework }},',
  tooltip: '. (Este balão aparece apenas no modo de desenvolvimento)',
  helpDoc: 'Documento de ajuda',
  offline: 'UI falha na conexão',
  restart: 'Por favor, tente reiniciar o serviço dev',
  loading: 'Carregar',
  code_1002: 'A interface do usuário do Umi não permite acesso IP não local por razões de segurança do projeto. Se você deseja acesso IP local, adicione a variável de ambiente HOST = 0.0.0.0 na inicialização'
};
exports.default = _default;